<template>
  <div class="blog-post">
    <Navbar/>  
    <div class="container-fluid">
      <SubNav>
        <template #link1>
          <router-link to="/blog"> 
            <u>Blog</u>>
          </router-link>
        </template>
        <template #current>
          15 самых востребованных профессий во Франции
        </template>
      </SubNav>
      <div class="row">
        <div class="col-12 offset-lg-3 col-lg-6">
          <PostHeader>
            <template #title>
                15 самых востребованных профессий во Франции по версии платформы LinkedIn
            </template>
            <template #date>
                15.08.2021
            </template>
          </PostHeader>

          <PostBodyIntroduction>
              <template #introduction>
                  <p>
                      Профессиональная социальная сеть LinkedIn опубликовала годовой отчет о самых востребованных специальностях во Франции. За последние годы произошло резкое увеличение количества людей, работающих в сфере защиты данных (le domaine de la protection des données). Только с 2015 года количество сотрудников по защите данных увеличилось в 32 раза. Так что если вы планируете найти работу во Франции, стоит внимательно изучить потребности рынка труда. Кроме того, зарплата, которую вы будете получать во Франции, также зависит от удачно выбранной специальности.
                  </p>
                  <p>
                      Специалисты LinkedIN говорят о том, что несмотря на то, что большинство новых профессий относится к технологическому сектору, выгоду от них получают и другие профессии - например, специализированный рекрутер. Тем не менее, сотрудники фирмы обращают внимание и на гендерное неравенство - около 81% в сфере новых профессий занято мужчинами. Однако области маркетинга и рекламы, управленческого консультирования более равны: 38% мужчин и 34% женщин соответственно. 
                  </p>
                  <p>
                      К слову сказать, LinkedIN очень востребованная платформа во Франции по поиску работы. На ней зарегистрировано более 16 миллионов пользователей. Через LinkedIN можно не только искать актуальные вакансии, но и расширить свои профессиональные связи. 
                  </p>
              </template>
          </PostBodyIntroduction>

          <PostBodySection>
            <template #title>
              Рейтинг  самых востребованных профессий во Франции по версии Linkedin:
            </template>
            <template #content>
              <div class="partie">
                <p><b>15. Аналитик прямых инвестиций</b></p>
                <p>Аналитик прямых инвестиций управляет большими базами данных. Он работает над сохранением и ростом инвестиционного фонда или портфеля фондов.</p>
              </div>

              <div class="partie">
                <p><b>14. Рекрутер в сфере IT</b></p>
                <p>Аналитик прямых инвестиций управляет большими базами данных. Он работает над сохранением и ростом инвестиционного фонда или портфеля фондов.</p>
              </div>

              <div class="partie">
                <p><b>13. Разработчик Big Data</b></p>
                <p>Аналитик прямых инвестиций управляет большими базами данных. Он работает над сохранением и ростом инвестиционного фонда или портфеля фондов.Особенно востребованы разработчики в таких секторах, как банковское дело или телекоммуникации. Они помогают своей компании создавать и поддерживать большой объем данных. На эти позиции существует большой спрос в Париже, Нанте, Тулузе, Лилле и Лионе.</p>
              </div>

              <div class="partie">
                <p><b>12. Рекрутер или специалист по подбору персонала</b></p>
                <p>Менеджер по подбору персонала отвечает за поиск, идентификацию и отбор кандидатов, соответствующих определенному профилю. Согласно LinkedIn, эта позиция будет приобретать все большее значение, потому что «решения о найме стратегических талантов имеют важное значение».</p>
              </div>

              <div class="partie">
                <p><b>11. Data консультант</b></p>
                <p>Консультанты по обработке данных пользуются большим спросом в Париже, Лионе, Тулузе и Нанте. Из работа -  определение того, какие технологии лучше всего соответствуют потребностям бизнеса. Специалисты, занимающие эту должность, должны хорошо разбираться в инструментах обработки данных.</p>
              </div>

              <div class="partie">
                <p><b>10. Data scientist (Специалист по данным)</b></p>
                <p>Специалисты по обработке данных заботятся об анализе и интерпретации данных. По данным LinkedIn, 76% рабочих мест находятся в Париже, но большой спрос также есть в Лилле, Лионе, Тулузе и Гренобле.</p>
              </div>

              <div class="partie">
                <p><b>9. Ingénieur data (Инженер данных)</b></p>
                <p>Что касается инженеров по обработке данных, они создают и поддерживают инфраструктуры, позволяющие компаниям классифицировать данные. Согласно LinkedIn, больше всего инженеров по обработке данных занято в банковской сфере и сфере технологий.</p>
              </div>

              <div class="partie">
                <p><b>8. DevOps инженер:</b></p>
                <p>Инженер DevOps должен работать с разработчиками программного обеспечения, системными операторами и другими членами команды, чтобы контролировать ИТ-инфраструктуру своей организации.</p>
              </div>

              <div class="partie">
                <p><b>7. Специалист по кибер-безопасности</b></p>
                <p>LinkedIn напоминает, что кибербезопасность имеет первостепенное значение для всех компаний, поэтому присутствие специалистов в этом списке неудивительно. Городами с высоким спросом являются Париж, Тулуза и Шамбери.</p>
              </div>

              <div class="partie">
                <p><b>6. Инженер по надежности сайта (SRE)</b></p>
                <p>Ответственный за выполнение операционных процессов и разработку веб-сайтов и приложений, инженер по надежности имеет навыки, применимые к другим инженерным должностям. Этот тип позиции особенно востребован в Париже, Ницце, Нанте, Лионе и Лилле.</p>
              </div>

              <div class="partie">
                <p><b>5. Community Manager</b></p>
                <p>Навыки коммьюнити- менеджера варьируются от Photoshop до маркетинга, - напоминает LinkedIn в своем отчете. Брендинг в социальных сетях является как никогда востребованным, поэтому спрос на специалистов только растет.</p>
              </div>

              <div class="partie">
                <p><b>4. Специалист по работе с клиентами (Customer Success Specialist)</b></p>
                <p>Специалист по работе с клиентами обладает навыками межличностного общения и техническими навыками в области управления взаимоотношениями с клиентами, имеет знание программного обеспечения (SaaS). Эта позиция особенно востребована в Париже.</p>
              </div>

              <div class="partie">
                <p><b>3. Риэлтор (Agent immobilier)</b></p>
                <p>Низкие процентные ставки повышают спрос на агентов по недвижимости. Париж, где цена квадратного метра продолжает расти, - город, где профессия риэлтора наиболее востребована.</p>
              </div>

              <div class="partie">
                <p><b>2. Инженер по искусственному интеллекту</b></p>
                <p>По данным LinkedIn, спрос на должности инженеров по искусственному интеллекту за 4 года увеличился в 16 раз. Искусственный интеллект и машинное обучение стали частью нашей жизни и неудивительно, что в Париже эти навыки пользуются наибольшим спросом.</p>
              </div>

              <div class="partie">
                <p><b>1. Сотрудник по защите данных</b></p>
                <p>Внедрение Общего режима защиты данных (RGPD) в 2018 году сделало эту позицию важной для компаний. Специалистов особенно ищут в сферах государственного управления, информационных технологий и услуг, банковского дела, медицины и страхования.</p>
              </div>
            </template>
          </PostBodySection>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import PostHeader from '@/components/blog/slots/PostHeader.vue'
import PostBodyIntroduction from '@/components/blog/slots/PostBodyIntroduction.vue'
import PostBodySection from '@/components/blog/slots/PostBodySection.vue'
import SubNav from '@/components/common/slots/SubNav.vue'
export default {
  name: 'BlogPost1',
  components: {
    Navbar,
    PostHeader,
    PostBodyIntroduction,
    PostBodySection,
    SubNav
  }
}
</script>
